@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;700&display=swap");
@import '~antd/dist/antd.css';



:root {
  --blue-card: #12036f;
  --red-card: #f12711;
  --yellow-card: #f5af19;
}

::selection,
::-moz-selection {
  @apply bg-teal-500 text-white;
}

::-webkit-scrollbar {
  @apply w-3;
}

::-webkit-scrollbar-track {
  @apply shadow rounded-lg bg-teal-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-teal-400 rounded-lg hover:bg-teal-500 transition-all duration-200;
}

button {
  user-select: none;
}

.footer li {
  margin: 0.5rem 0.2rem;
}


.invoice__preview {
  margin-top: 1.25rem;
}


@media (min-width: 1280px) {
  .invoice__preview {
    transform: scale(0.92);
    position: sticky;
    right: 0;
    top: 0;
    margin-top: -6rem;
    margin-bottom: 2rem;
  }
}


@media all {
  .pagebreak {
    overflow: hidden; height: 0;
  }
}

@media print {
  @page {
      size: landscape !important;
      /* margin: 16mm 16mm 16mm 16mm; */
  }


 

  .pagebreak {
    page-break-before: always;
  }

  div.page-footer, div.footer-info {
    display: block;
    position: fixed;
    bottom: 0;
}
}
