// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-form-item-explain {
    color: rgba($color: $black, $alpha: 0.5);
  }

  .ant-form-item-label > label .ant-form-item-tooltip {
    margin-inline-start: 5px;
    color: rgba($color: $black, $alpha: 0.5);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-form-item-explain {
    color: rgba($color: $white, $alpha: 0.5);
  }

  .ant-form-item-label > label .ant-form-item-tooltip {
    margin-inline-start: 5px;
    color: rgba($color: $white, $alpha: 0.5);
  }
}
