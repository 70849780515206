@import 'components/mixins.scss';

.backToTopInner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: $primary;
  color: $white;
  text-align: center;
  font-size: 28px;
}
