// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='default'] {
  .ant-back-top {
    bottom: 92px;
    z-index: 998;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
// [data-kit-theme='dark'] {
// }
